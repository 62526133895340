@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
        url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
        url('../assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
        url('../assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
        url('../assets/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
        url('../assets/fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
        url('../assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
        url('../assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
        url('../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
        url('../assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
        url('../assets/fonts/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
        url('../assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

