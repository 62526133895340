.wrapper {
	height: 100%;
	display: block;
	margin: 0 auto;
	.centered-wrapper {
		display: flex;
		height: 100%;
		align-items: center;
		.login-card {
			padding-top: 50px;
			padding-bottom: 50px;
			padding-left: 20px;
			padding-right: 30px;
			display: flex;
			flex-direction: column;
			width: 300px;
			border-radius: 3px;
			background-color: #ffffff;
			box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
				0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
			margin: 20px 10px;
			.login-card-title {
				text-align: center;
				line-height: 42px;
				color: #191919;
				font-size: 20px;
				font-weight: 600;
			}

			label {
				font-weight: 600;
			}

			input {
				margin-top: 10px;
				width: 100%;
				padding: 5px 5px 5px 5px;
				border-color: transparent;
				border: 2px solid #979797;
				color: #333;
				font-family: Montserrat;
				font-size: 16px;
				letter-spacing: 0;
				line-height: 19px;
			}

			.submit {
				min-width: 30%;
				margin: 0 auto;
				padding: 5px 5px 5px 5px;
				border-color: transparent;
				text-align: center;
				background-color: #191919;
				color: #ffffff;
				font-family: Montserrat;
				font-size: 16px;
				letter-spacing: 0;
				line-height: 19px;
			}
		}
	}
}
