/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;1,300;1,500;1,600;1,700&display=swap'); */
@import './fonts.css';

html, body {
  height: 100%;
  margin: 0;
  background: hsl(0, 0%, 96%);
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: visible;
}

@media only screen and (min-height: 1080px) {
  html, body {
    overflow-x: hidden;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}