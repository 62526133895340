.article-hits-chart-background {
    background: #191919;
}

.article-hits-chart-background .container {
    padding: 0px 12vmax 10px 12vmax;
}

.centre-flex-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center
}

@media only screen and (max-width: 750px) {
    .centre-flex-container {
        flex-direction: column;
    }

    .right-four-cards {
        display: flex;
        justify-content: center;
        align-content: center;
        align-self: center;
    }

    .left-large-card {
        justify-content: center;
        align-content: center;
        align-self: center;
    }

    .article-hits-chart-background .container {
        padding: 0px 5vmin 10px 5vmin;
    }
  }