.alltime-large-card {
	display: flex;
	flex-direction: column;
	padding-bottom: 90px;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
		0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	margin: 20px 10px;
}

.alltime-large-card-top {
	min-height: 70px;
	background-color: #191919;
	color: white;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	.top-header {
		line-height: 70px;
		&.has-sub-heading{
			line-height: 40px;
		}
	}
}

.alltime-large-card-header {
	margin-top: 40px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	align-self: center;
	column-gap: 2rem;
	.alltime-large-card-header-box {
		background: #f5f5f5;
		border-radius: 57px;
		padding: 1.5rem 0 1rem 0;
		.header {
			padding-left: 120px;
			padding-right: 120px;
			display: flex;
			justify-content: center;
			.header-text {
				font-weight: 600;
				font-size: 30px;
				line-height: 37px;
				text-align: center;
				&.blue-header {
					color: #175dd0;
				}
				&.green-header {
					color: #1a9d11;
				}
				&.red-header {
					color: #cc011a;
				}
			}

			.alltime-large-card-circle-arrow {
				margin-left: 10px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: #1a9d11;
				box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
			}

			.alltime-large-card-circle-arrow-image {
				height: 20px;
				width: 20px;
				background-repeat: no-repeat;
				background-size: contain;
				display: block;
				margin-left: 6px;
				margin-top: 5px;
			}
		}
		span {
			display: block;
			text-align: center;
			font-weight: 300;
			font-size: 14px;
			line-height: 17px;
			color: #191919;
			text-align: center;
			margin-top: 10px;
		}
	}
}

.alltime-large-card-content {
	padding-left: 3.5rem;
	padding-right: 3.5rem;
	margin-top: 40px;
	line-height: 180px;
	font-size: 40px;
	font-weight: 700;
	height: 450px;
	position: relative;
}

@media only screen and (max-width: 1000px) {
	.alltime-large-card {
		.alltime-large-card-header {
			.alltime-large-card-header-box {
				.header {
					padding-left: 50px !important;
					padding-right: 50px !important;
				}
			}
		}
		.alltime-large-card-content {
			height: 325px !important;
			padding-left: 1.5rem !important;
			padding-right: 1.5rem !important;
		}
	}
}

@media only screen and (max-width: 600px) {
	.alltime-large-card-header {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		align-self: stretch;
		row-gap: 30px;
		margin-left: 30px;
		margin-right: 30px;
		.alltime-large-card-header-box {
			padding: 0 0 0.5rem 0 !important;
			.header {
				padding-left: 2rem !important;
				padding-right: 2rem !important;
				.header-text {
					font-size: 1.5rem !important;
				}
			}
			.header-text-base {
				margin-top: 0 !important;
			}
		}
	}
}
