.campaigns-large-card {
	display: flex;
	flex-direction: column;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
		0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.campaigns-large-card-top {
	min-height: 70px;
	background-color: #191919;
	color: white;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	.top-header {
		line-height: 70px;
		cursor: pointer;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		&.has-sub-heading {
			line-height: 40px;
		}
	}
}

.campaigns-large-card-header {
	margin-top: 40px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	align-self: stretch;
	row-gap: 30px;
	margin-left: 30px;
	margin-right: 30px;
	.campaigns-large-card-header-box {
		background: #f5f5f5;
		border-radius: 57px;
		padding: 0 0 0.5rem 0;
		.header-text-base {
			margin-top: 0;
		}
		.header {
			padding-left: 2rem;
			padding-right: 2rem;
			display: flex;
			justify-content: center;
			.header-text {
				font-weight: 600;
				font-size: 1.5rem;
				text-align: center;
				&.blue-header {
					color: #175dd0;
				}
				&.green-header {
					color: #1a9d11;
				}
				&.red-header {
					color: #cc011a;
				}
			}

			.campaigns-large-card-circle-arrow {
				margin-left: 10px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: #1a9d11;
				box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
			}

			.campaigns-large-card-circle-arrow-image {
				height: 20px;
				width: 20px;
				background-repeat: no-repeat;
				background-size: contain;
				display: block;
				margin-left: 6px;
				margin-top: 5px;
			}
		}
		span {
			display: block;
			text-align: center;
			font-weight: 300;
			font-size: 14px;
			color: #191919;
			text-align: center;
			margin-top: 10px;
		}
	}
}

@media only screen and (min-width: 992px) {
	.campaigns-large-card-header {
		margin-top: 40px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		align-self: center;
		column-gap: 2rem;
		.campaigns-large-card-header-box {
			background: #f5f5f5;
			border-radius: 57px;
			padding: 0.5rem 0 0.5rem 0;
			.header-text-base {
				margin-top: 0 !important;
			}
			.header {
				padding-left: 120px;
				padding-right: 120px;
				display: flex;
				justify-content: center;
				.header-text {
					font-weight: 600;
					font-size: 1.75rem;
					text-align: center;
					&.blue-header {
						color: #175dd0;
					}
					&.green-header {
						color: #1a9d11;
					}
					&.red-header {
						color: #cc011a;
					}
				}

				.campaigns-large-card-circle-arrow {
					margin-left: 10px;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background-color: #1a9d11;
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
				}

				.campaigns-large-card-circle-arrow-image {
					height: 20px;
					width: 20px;
					background-repeat: no-repeat;
					background-size: contain;
					display: block;
					margin-left: 6px;
					margin-top: 5px;
				}
			}
			span {
				display: block;
				text-align: center;
				font-weight: 300;
				font-size: 14px;
				color: #191919;
				text-align: center;
				margin-top: 10px;
			}
		}
	}
}

@media only screen and (min-width: 1200px) {
	.campaigns-large-card-header {
		margin-top: 40px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		align-self: center;
		column-gap: 2rem;
		.campaigns-large-card-header-box {
			background: #f5f5f5;
			border-radius: 57px;
			padding: 0.5rem 0 0.5rem 0;
			.header-text-base {
				margin-top: 0 !important;
			}
			.header {
				padding-left: 120px;
				padding-right: 120px;
				display: flex;
				justify-content: center;
				.header-text {
					font-weight: 600;
					font-size: 1.75rem;
					text-align: center;
					&.blue-header {
						color: #175dd0;
					}
					&.green-header {
						color: #1a9d11;
					}
					&.red-header {
						color: #cc011a;
					}
				}

				.campaigns-large-card-circle-arrow {
					margin-left: 10px;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background-color: #1a9d11;
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
				}

				.campaigns-large-card-circle-arrow-image {
					height: 20px;
					width: 20px;
					background-repeat: no-repeat;
					background-size: contain;
					display: block;
					margin-left: 6px;
					margin-top: 5px;
				}
			}
			span {
				display: block;
				text-align: center;
				font-weight: 300;
				font-size: 14px;
				color: #191919;
				text-align: center;
				margin-top: 10px;
			}
		}
	}
}

.campaigns-large-card-content {
	.campaigns-large-card-chart {
		height: 325px;
		margin-bottom: 1.5rem;
	}
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	margin-top: 25px;
	margin-bottom: 30px;
	font-size: 40px;
	font-weight: 700;
	position: relative;
	&.single-row {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
	}
	.legend-list {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: 100%;
		.legend-item {
			font-size: 1rem;
			font-weight: 400;
			padding-bottom: 10px;
			align-items: center;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			margin-left: 10px;
			span.legend-color-box {
				display: inline-block;
				flex-shrink: 0;
				height: 20px;
				width: 20px;
				margin-right: 10px;
				border-style: solid;
			}
		}
	}
}

@media only screen and (min-width: 1200px) {
	.campaigns-large-card-content {
		.campaigns-large-card-chart {
			height: 350px;
		}
	}
}

@media only screen and (min-width: 992px) {
	.campaigns-large-card {
		.campaigns-large-card-header {
			.campaigns-large-card-header-box {
				.header {
					padding-left: 50px;
					padding-right: 50px;
				}
			}
		}
		.campaigns-large-card-content {
			&.single-row {
				.campaigns-large-card-chart {
					height: 500px;
					margin-bottom: 0;
				}
				grid-template-columns: 4fr 1fr;
				grid-template-rows: 1fr;
				&.single-row-no-legend {
					grid-template-columns: auto !important;
				}
			}
			&.three-row {
				.campaigns-large-card-chart {
					height: 300px;
				}
			}
		}
	}
}
