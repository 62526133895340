.app {
  display: flex;
  flex-flow: column;
  height: 100%;
}

@media only screen and (min-width: 1900px) {
  .app {
    height: 100vh;
    min-height: calc(100vh);
  }
}