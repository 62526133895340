.small-card {
    display: flex;
    flex-direction: column;
    width: 10vmax;
    margin: 20px 10px;

    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
}

.small-card-top {
    height: 42px;
    line-height: 42px;
    background-color: #191919;
    color:white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-size: 14px;
    font-weight: 600;
}

.small-card-mid {
    height: 75px;
    line-height: 75px;
    font-size: 22px;
    font-weight: 600;
}

.small-card-bottom {
    height: 25px;
}

.small-card-circle {
    margin: auto;
    margin-bottom: 15px;
    margin-top: 15px;
    height: 75px;
    width: 75px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

.small-card-circle-arrow {
    content: "";
    position: absolute;
    transform:translate(100%,0%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #1A9D11;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
}

.small-card-circle-arrow-image {
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    background-size:contain;
    display: block;
    margin-left: 5px;
    margin-top: 4px;
    position: relative;
}

svg {
	max-width: 100%;
	vertical-align: middle;
	z-index: 99;
    transform: rotate(-90deg);
}

.svg-indicator-sm {
	width: 90px;
	height: 90px;
}

.svg-indicator-track-sm,
.svg-indicator-indication-sm {
	cx: 47px;
	cy: 38px;
	r: 36px;
	fill: transparent;
	stroke-width: 5px;
}

.svg-indicator-indication-sm {
	stroke: #6a6a6a;
}


@media only screen and (max-width: 1000px) {
    .small-card {
        width: 20vmin !important;
    }
  }

  @media only screen and (max-width: 750px) {
    .small-card {
        width: 40vmin !important;
    }
  }