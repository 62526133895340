.dashboard-split-view {
    text-align: center;
    display: flex;
    flex:2;
  }
  
  .cove-plus-view {
      width: 50vw;
      background: rgba(0, 0, 0, 0.05);
      flex: 1 1 50vw;
      min-height: 100%;
  }
  
  .the-cove-view {
    width: 50vw;
    flex: 1 1 50vw;
    min-height: 100%;
  }

  .the-cove-logo-smallscreen {
    display: none;
  }

  .cove-plus-logo-smallscreen {
    display: none;
  }

  @media only screen and (max-width: 1000px) {
    .dashboard-split-view {
      display: initial;
    }

    .cove-plus-view {
      width: 100vw;
      flex: 1 1 100vw;
      min-height: 1060px;
  }
  
  .the-cove-view {
    width: 100vw;
    flex: 1 1 100vw;
    min-height: 1100px;
  }

  .the-cove-logo-smallscreen {
    display: block;
  }

  .cove-plus-logo-smallscreen {
    display: block;
  }
  }

  @media only screen and (max-width: 750px) {
    .cove-plus-view {
      width: 100vw;
      flex: 1 1 100vw;
      min-height: 1200px;
  }
  
  .the-cove-view {
    width: 100vw;
    flex: 1 1 100vw;
    min-height: 1480px;
  }
  }

  @media only screen and (max-width: 550px) {
    .cove-plus-view {
      width: 100vw;
      flex: 1 1 100vw;
      min-height: 1200px;
  }
  
  .the-cove-view {
    width: 100vw;
    flex: 1 1 100vw;
    min-height: 1430px;
  }
  }