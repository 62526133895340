.large-card {
	display: flex;
	flex-direction: column;
	height: 364px;
	width: 20vmax;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
		0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	margin: 20px 10px;
}

.large-card-top {
	height: 42px;
	line-height: 42px;
	background-color: #191919;
	color: white;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	font-size: 14px;
	font-weight: 600;
}

.large-card-mid {
	height: 180px;
	line-height: 180px;
	font-size: 40px;
	font-weight: 700;
}

.large-card-bottom {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 20px;
}

.large-card-circle {
	margin: auto;
	margin-bottom: 15px;
	margin-top: 15px;
	height: 180px;
	width: 180px;
	background-color: #f5f5f5;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	margin-top: 50px;
}

.large-card-circle-arrow {
	content: '';
	position: absolute;
	transform: translate(150%, 10%);
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #1a9d11;
    z-index: 99;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.large-card-circle-arrow-image {
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	margin-left: 6px;
	margin-top: 5px;
	position: relative;
}

svg {
	max-width: 100%;
	vertical-align: middle;
	z-index: 99;
    transform: rotate(-90deg);
}

.svg-indicator {
	width: 200px;
	height: 200px;
}

.svg-indicator-track,
.svg-indicator-indication {
	cx: 90px;
	cy: 90px;
	r: 88px;
	fill: transparent;
	stroke-width: 5px;
}

@media only screen and (max-width: 1000px) {
	.large-card {
		width: 40vmin;
	}
}

@media only screen and (max-width: 750px) {
	.large-card {
		width: 80vmin !important;
	}
}
