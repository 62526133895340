.centre-top-index {
	z-index: 2;
	color: #191919;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 70px;

	/* HACK: to scroll with content*/
	text-align: center;
	position: relative;
	top: 5px;
	width: 100vw;
}
