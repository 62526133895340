.year-block {
	text-align: center;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
		0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	width: 30%;
	margin: 0 auto;
	margin-top: 30px;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 20px;
}

.year-title-block {
	background-color: #191919;
	color: white;
	width: 100%;
	height: 46px;
	line-height: 46px;
	border-radius: 5px;
}
.alltime-block{
    background-color: #CC011A;
	cursor: pointer;
}

.accordion {
	cursor: pointer;
	transition: 0.4s;
}

.panel {
	background-color: white;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

.month-block {
	background-color: white;
	color: #333333;
	width: 100%;
	line-height: 46px;
	border-bottom: 1px solid #bfbfbf;
	cursor: pointer;
}

.month-block-bottom {
	background-color: white;
	color: #333333;
	width: 100%;
	height: 46px;
	line-height: 46px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	cursor: pointer;
}

a {
	text-decoration: none;
}

@media only screen and (max-width: 1200px) {
	.year-block {
		width: 40%;
	}
}

@media only screen and (max-width: 600px) {
	.year-block {
		width: 50%;
	}
}
