.toplist-card {
	display: flex;
	flex-direction: column;
	width: calc(40vmin + 40px);
	height: 310px;

	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
		0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
}

.toplist-card-top {
	height: 42px;
	line-height: 42px;
	background-color: #191919;
	color: white;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	font-size: 14px;
	font-weight: 600;
}

.toplist-inner-cols {
	display: flex;
	line-height: normal;
	flex-direction: column;
	margin: 24px;
	margin-left: 19px;
	margin-bottom: 0;
}

.toplist-inner-content-flex {
	display: flex;
	flex-direction: column;
	width: 100%;
}

a {
	text-decoration: none;
}

.toplist-inner-flex .container-content {
	display: flex;
}

.toplist-inner-flex .container-bottom {
	margin-bottom: 20px;
	color: black;
	display: flex;
	margin-bottom: 16px;
	padding-bottom: 16px;
}

.container-content img {
	margin-right: 15px;
	width: 62px;
	height: 50px;
}

.toplist-inner-content {
	flex-grow: 1;
	color: #333333;
	font-size: 14px;
	font-weight: 600;
	line-height: normal;
	text-align: left;
	margin-bottom: 5px;
}

.container-bottom .flex-start {
	font-weight: 500;
}

.container-bottom .flex-end {
	text-align: end;
	flex-grow: 1;
	color: #1a9d11;
	font-weight: 500;
}

.toplist-inner-content {
	display: block; /* or inline-block */
	text-overflow: ellipsis;
	word-wrap: break-word;
	overflow: hidden;
	line-height: 1.8em;
}

@media only screen and (max-width: 1000px) {
	.toplist-card {
		width: calc(80vmin + 40px) !important;
	}
}

@media only screen and (max-width: 750px) {
	.toplist-card {
		width: calc(80vmin + 20px) !important;
	}

	.toplist-card {
		align-content: center;
		align-self: center;
	}

	.container-bottom {
		flex-direction: column;
		text-align: start;
	}
}
