.campaign-wrapper {
	width: 100%;
	.campaign-container-single {
		display: grid;
		grid-template-columns: 1fr;
		margin: 0 auto;
		max-width: 1920px;
		margin-bottom: 50px;
		column-gap: 40px;
	}

	.campaign-container {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		margin: 0 auto;
		max-width: 1920px;
		row-gap: 20px;
	}
}

@media only screen and (min-width: 992px) {
	.campaign-wrapper {
		.campaign-container {
			grid-template-columns: 1fr 1fr;
			column-gap: 20px;
		}
	}
}

@media only screen and (min-width: 1200px) {
	.campaign-wrapper {
		.campaign-container {
			grid-template-columns: 1fr 1fr 1fr !important;
			column-gap: 40px;
			row-gap: 50px;
		}
	}
}
