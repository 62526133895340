.unit-enrols-chart-background {
    background: #000000;
}

.unit-enrols-chart-background .container {
    padding: 0px 12vmax 10px 12vmax;
}

.centre-flex-container {
    width: 100%;
    display: flex;
    justify-content: center
}

@media only screen and (max-width: 750px) {
    .unit-enrols-chart-background .container {
        padding: 0px 5vmin 10px 5vmin;
    }
  }