.top-banner {
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
}

.centre-top {
	color: #191919;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 35px;
	z-index: 2;

	/* HACK: to scroll with content*/
	text-align: center;
	position: relative;
	top: 5px;
	width: 100vw;
}

.centre-top a {
	color: black;
}

.center-top-month-small {
	display: none;
}

.center-top-month-large {
	display: inline-block;
}

.month-nav-arrows {
	height: 20px;
	width: 15px;
	margin-left: 20px;
	margin-right: 20px;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
}

.centre-top-month {
	color: #cc011a;
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 29px;
}

.centre-top:after {
	position: fixed;
	display: none;
	width: 200px;
	height: 80px;
	top: 0px;
	left: calc(50% - 200px);
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	cursor: pointer;
}

.cove-plus-logo {
	width: 50vw;
	height: 80px;
	background: white;
}

.the-cove-logo {
	width: 50vw;
	height: 80px;
	background: white;
}

.top-banner-split-view {
	position: absolute;
	top: 0;
	text-align: center;
	display: flex;
}

.top-banner-split-view img {
	margin: 0;
	position: relative;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@media only screen and (max-width: 1000px) {
	.top-banner-split-view {
		display: none;
	}

	.center-top-month-small {
		display: inline-block;
	}

	.center-top-month-large {
		display: none;
	}
	.month-nav-arrows {
		margin-left: 5px;
		margin-right: 5px;
	}
}

@media only screen and (max-width: 670px) {
	.cove-plus-logo {
		height: 80px;
	}

	.the-cove-logo {
		height: 80px;
	}
}

@media only screen and (max-width: 320px) {
	.cove-plus-logo {
		height: 120px;
	}

	.the-cove-logo {
		height: 120px;
	}
}
