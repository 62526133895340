.alltimepage {
	overflow: scroll;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
}

.alltime-wrapper {
	margin: 0 auto;
	width: 93vw;
	max-width: 1920px;
	.two-container {
		display: flex;
		.alltime-large-card {
			width: 50%;
			padding-bottom: 3.5rem;
			.alltime-large-card-header-box {
				padding: 1rem 0 0.5rem 0;
				.header {
					padding-left: 2.5rem !important;
					padding-right: 2.5rem !important;
					.header-text {
						font-size: 1.75rem;
					}
				}
				.header-text-base {
					margin-top: 0.5rem;
				}
			}
			.alltime-large-card-content {
				padding-left: 2.5rem;
				padding-right: 2.5rem;
				height: 325px !important;
			}
		}
	}
	.three-container {
		display: flex;
		.alltime-large-card {
			width: 33.33%;
			padding-bottom: 2rem;
			.alltime-large-card-header-box {
				padding: 0 0 0.5rem 0;
				.header {
					padding-left: 2rem !important;
					padding-right: 2rem !important;
					.header-text {
						font-size: 1.5rem;
					}
				}
				.header-text-base {
					margin-top: 0;
				}
			}
			.alltime-large-card-content {
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				height: 200px !important;
			}
		}
	}

	@media only screen and (max-width: 1000px) {
		.two-container {
			flex-direction: column;
			.alltime-large-card {
				width: auto;
			}
		}
		.three-container {
			flex-direction: column;
			.alltime-large-card {
				width: auto;
			}
		}
	}
}
